import { LAST_VALUE_START_DATE } from 'utils/common'

export const QUERY_FOR_LOGIN = `
  query login ($email: String!, $password: String!) {
    login (email: $email, password: $password) {
      user {
        accessibleCompanyDevices {
          name
          id
          deviceConfig
        }
      }
      token
    }
  }
`

export const DUMMY_QUERY = `
  query calculateTrend {
    calculateTrend(
      deviceId: "1"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        category: QUANTITIES
        subCategory: THROUGHPUT
        variable: MEASUREMENT
        index: 1
        variableIndex: 1
        calculationTimeRange: 3600000
        aggregation: Max
      }
    ) {
      value
      timestamp
    }
  }
`

export const QUERY_FOR_BURNER_PERFORMANCE = `
  query dryDetailBurner {
    calculateTrend (
      deviceId: "{{{deviceId}}}"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        calculationTimeRange: 3600000
        dataSources: [{
          category: DRYING
          subCategory: BURNER_PERFORMANCE
          variable: MEASUREMENT
          index: 1
          variableIndex: 1
          aggregation: TimeWeightedAverage
        }]
      }
    ) {
      values {
        value
        timestamp
      }
    }
  }
`

export const QUERY_FOR_DRYING = (queryNumber: number) => `
  query calculateTrend {${new Array(queryNumber).fill(0).map(
  (_, index) => `
    drying_${index + 1}: calculateTrend (
      deviceId: "{{{deviceId}}}"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        calculationTimeRange: 3600000
        dataSources: [{
          category: QUANTITIES
          subCategory: THROUGHPUT
          variable: MEASUREMENT
          index: ${index + 1}
          variableIndex: 1
          aggregation: TimeWeightedAverage
        }]
      }
    ) {
      values {
        value
        timestamp
      }
    }`,
)}}
`

export const QUERY_FOR_ENERGY_DAY = `
  query energyDaily {
    airPressure: calculateTrend (
      deviceId: "{{{deviceId}}}"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        calculationTimeRange: 3600000
        dataSources: [{
          category: ENERGY_MANAGEMENT
          subCategory: COMPRESSED_AIR_QUANTITY
          variable: MEASUREMENT
          index: 1
          variableIndex: 1
          aggregation: TimeWeightedAverage
        }]
      }
    ) {
      values {
        value
        timestamp
      }
    }
    gas: calculateTrend (
      deviceId: "{{{deviceId}}}"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        calculationTimeRange: 3600000
        dataSources: [{
          category: ENERGY_MANAGEMENT
          subCategory: GAS
          variable: MEASUREMENT
          index: 1
          variableIndex: 1
          aggregation: TimeWeightedAverage
        }]
      }
    ) {
      values {
        value
        timestamp
      }
    }
    power: calculateTrend (
      deviceId: "{{{deviceId}}}"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        calculationTimeRange: 3600000
        dataSources: [{
          category: ENERGY_MANAGEMENT
          subCategory: PERFORMANCE
          variable: MEASUREMENT
          index: 1
          variableIndex: 1
          aggregation: TimeWeightedAverage
        }]
      }
    ) {
      values {
        value
        timestamp
      }
    }
  }
`

export const QUERY_FOR_ENERGY_PREV_DAILY = `
  query energyPrevDaily {
    gas: data(
      deviceId: "{{{deviceId}}}"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        category: ENERGY_MANAGEMENT
        subCategory: GAS
        variable: SUM_PREV_DAY
        index: 1
        variableIndex: 1
      }
    ) {
      value
      timestamp
    }
    power: data(
      deviceId: "{{{deviceId}}}"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        category: ENERGY_MANAGEMENT
        subCategory: PERFORMANCE
        variable: SUM_PREV_DAY
        index: 1
        variableIndex: 1
      }
    ) {
      value
      timestamp
    }
    airPressure: data(
      deviceId: "{{{deviceId}}}"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        category: ENERGY_MANAGEMENT
        subCategory: COMPRESSED_AIR_QUANTITY
        variable: SUM_PREV_DAY
        index: 1
        variableIndex: 1
      }
    ) {
      value
      timestamp
    }
  }
`

export const QUERY_FOR_ENERGY_CURRENT_DAILY = `
  query energyCurrentDaily {
    gas: data(
      deviceId: "{{{deviceId}}}"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        category: ENERGY_MANAGEMENT
        subCategory: GAS
        variable: SUM_CURRENT_DAILY
        index: 1
        variableIndex: 1
        limit: "1"
        order: Descending
      }
    ) {
      value
      timestamp
    }
    power: data (
      deviceId: "{{{deviceId}}}"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        category: ENERGY_MANAGEMENT
        subCategory: PERFORMANCE
        variable: SUM_CURRENT_DAILY
        index: 1
        variableIndex: 1
        limit: "1"
        order: Descending
      }
    ) {
      value
      timestamp
    }
    airPressure: data (
      deviceId: "{{{deviceId}}}"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        category: ENERGY_MANAGEMENT
        subCategory: COMPRESSED_AIR_QUANTITY
        variable: SUM_CURRENT_DAILY
        index: 1
        variableIndex: 1
        limit: "1"
        order: Descending
      }
    ) {
      value
      timestamp
    }
  }
`

export const QUERY_FOR_INPUT = (queryNumber: number) => `
  query calculateTrend {${new Array(queryNumber).fill(0).map(
  (_, index) => `
    input_${index + 1}: calculateTrend (
      deviceId: "{{{deviceId}}}"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        calculationTimeRange: 3600000
        dataSources: [{
          category: QUANTITIES
          subCategory: INPUT
          variable: MEASUREMENT
          index: ${index + 1}
          variableIndex: 1
          aggregation: TimeWeightedAverage
        }]
      }
    ) {
      values {
        value
        timestamp
      }
    }`,
)}}
`

export const QUERY_FOR_LAST_INPUT = (queryNumber: number) => `
  query calculateTrend {${new Array(queryNumber).fill(0).map(
  (_, index) => `
    input_${index + 1}: data (
      deviceId: "{{{deviceId}}}"
      data: {
        from: "${LAST_VALUE_START_DATE}"
        to: "{{{to}}}"
        category: QUANTITIES
        subCategory: INPUT
        variable: MEASUREMENT
        index: ${index + 1}
        variableIndex: 1
        limit: "1"
        order: Descending
      }
    ) {
      value
      timestamp
    }`,
)}}
`

export const QUERY_FOR_OUTPUT = (queryNumber: number) => `
  query calculateTrend {${new Array(queryNumber).fill(0).map(
  (_, index) => `
    output_${index + 1}: calculateTrend (
      deviceId: "{{{deviceId}}}"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        calculationTimeRange: 3600000
        dataSources: [{
          category: QUANTITIES
          subCategory: OUTPUT
          variable: MEASUREMENT
          index: ${index + 1}
          variableIndex: 1
          aggregation: TimeWeightedAverage
        }]
      }
    ) {
      values {
        value
        timestamp
      }
    }`,
)}}
`

export const QUERY_FOR_LAST_OUTPUT = (queryNumber: number) => `
  query calculateTrend {${new Array(queryNumber).fill(0).map(
  (_, index) => `
    output_${index + 1}: data (
      deviceId: "{{{deviceId}}}"
      data: {
        from: "${LAST_VALUE_START_DATE}"
        to: "{{{to}}}"
        category: QUANTITIES
        subCategory: OUTPUT
        variable: MEASUREMENT
        index: ${index + 1}
        variableIndex: 1
        limit: "1"
        order: Descending
      }
    ) {
      value
      timestamp
    }`,
)}}
`

export const QUERY_FOR_DRYING_PREV_DAILY = `
  query dryingDaily {
    drying: data (
      deviceId: "{{{deviceId}}}"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        category: QUANTITIES
        subCategory: THROUGHPUT
        variable: SUM_PREV_DAY
        index: 1
        variableIndex: 1
      }
    ) {
      value
      timestamp
    }
  }
`

export const QUERY_FOR_DRYING_CURRENT_DAILY = `
query dryingToday {
  drying: data (
    deviceId: "{{{deviceId}}}"
    data: {
      from: "{{{from}}}"
      to: "{{{to}}}"
      category: QUANTITIES
      subCategory: THROUGHPUT
      variable: SUM_CURRENT_DAILY
      index: 1
      variableIndex: 1
      limit: "1"
      order: Descending
    }
  ) {
    value
    timestamp
  }
}
`

export const QUERY_FOR_INPUT_PREV_DAILY = `
  query inputDaily {
    input: data (
      deviceId: "{{{deviceId}}}"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        category: QUANTITIES
        subCategory: INPUT
        variable: SUM_PREV_DAY
        index: 1
        variableIndex: 1
      }
    ) {
      value
      timestamp
    }
  }
`

export const QUERY_FOR_INPUT_CURRENT_DAILY = `
query inputToday {
  input: data (
    deviceId: "{{{deviceId}}}"
    data: {
      from: "{{{from}}}"
      to: "{{{to}}}"
      category: QUANTITIES
      subCategory: INPUT
      variable: SUM_CURRENT_DAILY
      index: 1
      variableIndex: 1
      limit: "1"
      order: Descending
    }
  ) {
    value
    timestamp
  }
}
`

export const QUERY_FOR_OUTPUT_PREV_DAILY = (queryNumber: number) => `
  query outputDaily {${new Array(queryNumber).fill(0).map(
  (_, index) => `
      output_${index + 1}: data (
        deviceId: "{{{deviceId}}}"
        data: {
          from: "{{{from}}}"
          to: "{{{to}}}"
          category: QUANTITIES
          subCategory: OUTPUT
          variable: SUM_PREV_DAY
          index: ${index + 1}
          variableIndex: 1
        }
      ) {
        value
        timestamp
      }`,
)}}
`

export const QUERY_FOR_OUTPUT_CURRENT_DAILY = (queryNumber: number) => `
  query outputToday {${new Array(queryNumber).fill(0).map(
  (_, index) => `
      output_${index + 1}: data (
        deviceId: "{{{deviceId}}}"
        data: {
          from: "{{{from}}}"
          to: "{{{to}}}"
          category: QUANTITIES
          subCategory: OUTPUT
          variable: SUM_CURRENT_DAILY
          index: ${index + 1}
          variableIndex: 1
          limit: "1"
          order: Descending
        }
      ) {
        value
        timestamp
      }`,
)}}
`

export const QUERY_FOR_SORTING = (queryNumber: number) => `
  query sortingMachine {${new Array(queryNumber).fill(0).map(
  (_, index) => `
    sort_rate_${index + 1}: calculateTrend (
      deviceId: "{{{deviceId}}}"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        calculationTimeRange: 3600000
        dataSources: [{
          category: SORTING
          subCategory: SORTING_MACHINE
          variable: SORT_RATE
          index: {{{machineId}}}
          variableIndex: ${index + 1}
          aggregation: TimeWeightedAverage
        }]
      }
    ) {
      values {
        value
        timestamp
      }
    }
    sort_rate_${index + 1}_alarm: data (
      deviceId: "{{{deviceId}}}"
      data: {
        from: "${LAST_VALUE_START_DATE}"
        to: "{{{to}}}"
        category: SORTING
        subCategory: SORTING_MACHINE
        variable: SORT_RATE_ALARM_LIMIT
        index: {{{machineId}}}
        variableIndex: ${index + 1}
        limit: "1"
        order: Descending
      }
    ) {
      value
      timestamp
    }
    sort_rate_${index + 1}_warning: data (
      deviceId: "{{{deviceId}}}"
      data: {
        from: "${LAST_VALUE_START_DATE}"
        to: "{{{to}}}"
        category: SORTING
        subCategory: SORTING_MACHINE
        variable: SORT_RATE_WARNING_LIMIT
        index: {{{machineId}}}
        variableIndex: ${index + 1}
        limit: "1"
        order: Descending
      }
    ) {
      value
      timestamp
    }`,
)}}
`

export const QUERY_FOR_TEMPERATURES = (queryNumber: number) => `
  query calculateTrend {${new Array(queryNumber).fill(0).map(
  (_, index) => `
    temperature_${index + 1}: calculateTrend (
      deviceId: "{{{deviceId}}}"
      data: {
        from: "{{{from}}}"
        to: "{{{to}}}"
        calculationTimeRange: 3600000
        dataSources: [{
          category: DRYING
          subCategory: TEMPERATURE
          variable: MEASUREMENT
          index: ${index + 1}
          variableIndex: 1
          aggregation: TimeWeightedAverage
        }]
      }
    ) {
      values {
        value
        timestamp
      }
    }`,
)}}
`

export const QUERY_FOR_DASHBOARD_SORTING_MACHINES = (queryNumber: number) => `
  query sortingMachinesStatus($deviceId: String!, $from: DateTime!, $to: DateTime!) {
    bulkData(
      deviceId: $deviceId
      data: [
        ${new Array(queryNumber)
  .fill(null)
  .map(
    (_, index) => `
          {
            from: $from
            to: $to
            category: SORTING
            subCategory: SORTING_MACHINE
            variable: MACHINE_MALFUNCTION
            index: ${index + 1}
            variableIndex: 1
            limit: "1"
            order: Descending
          },
          {
            from: $from
            to: $to
            category: SORTING
            subCategory: SORTING_MACHINE
            variable: MACHINE_WARNING
            index: ${index + 1}
            variableIndex: 1
            limit: "1"
            order: Descending
          }
        `,
  )
  .join(',')}
      ]
    ) {
      values {
        value
        timestamp
      }
    }
  }
`

export const QUERY_FOR_DASHBOARD_DRYING = (queryNumber: number) => `
  query dryOverview($deviceId: String!, $to: DateTime!) {
    bulkData(
      deviceId: $deviceId
      data: [
        ${[...Array(queryNumber)]
  .flatMap((_, index) => [
    `{
            from: "${LAST_VALUE_START_DATE}",
            to: $to,
            category: DRYING,
            subCategory: TEMPERATURE,
            variable: MEASUREMENT,
            index: ${index + 1},
            variableIndex: 1
            limit: "1"
            order: Descending
          }`,
    `{
            from: "${LAST_VALUE_START_DATE}",
            to: $to,
            category: DRYING,
            subCategory: TEMPERATURE,
            variable: ALARM_LIMIT_UPPER,
            index: ${index + 1},
            variableIndex: 1,
            limit: "1",
            order: Descending
          }`,
    `{
            from: "${LAST_VALUE_START_DATE}",
            to: $to,
            category: DRYING,
            subCategory: TEMPERATURE,
            variable: ALARM_LIMIT_UPPER_ACTIVE,
            index: ${index + 1},
            variableIndex: 1,
            limit: "1",
            order: Descending
          }`,
    `{
            from: "${LAST_VALUE_START_DATE}",
            to: $to,
            category: DRYING,
            subCategory: TEMPERATURE,
            variable: ALARM_LIMIT_LOWER,
            index: ${index + 1},
            variableIndex: 1,
            limit: "1",
            order: Descending
          }`,
    `{
            from: "${LAST_VALUE_START_DATE}",
            to: $to,
            category: DRYING,
            subCategory: TEMPERATURE,
            variable: ALARM_LIMIT_LOWER_ACTIVE,
            index: ${index + 1},
            variableIndex: 1,
            limit: "1",
            order: Descending
          }`,
    `{
            from: "${LAST_VALUE_START_DATE}",
            to: $to,
            category: DRYING,
            subCategory: TEMPERATURE,
            variable: WARNING_LIMIT_UPPER,
            index: ${index + 1},
            variableIndex: 1,
            limit: "1",
            order: Descending
          }`,
    `{
            from: "${LAST_VALUE_START_DATE}",
            to: $to,
            category: DRYING,
            subCategory: TEMPERATURE,
            variable: WARNING_LIMIT_UPPER_ACTIVE,
            index: ${index + 1},
            variableIndex: 1,
            limit: "1",
            order: Descending
          }`,
    `{
            from: "${LAST_VALUE_START_DATE}",
            to: $to,
            category: DRYING,
            subCategory: TEMPERATURE,
            variable: WARNING_LIMIT_LOWER,
            index: ${index + 1},
            variableIndex: 1,
            limit: "1",
            order: Descending
          }`,
    `{
            from: "${LAST_VALUE_START_DATE}",
            to: $to,
            category: DRYING,
            subCategory: TEMPERATURE,
            variable: WARNING_LIMIT_LOWER_ACTIVE,
            index: ${index + 1},
            variableIndex: 1,
            limit: "1",
            order: Descending
          }`,
  ])
  .join(',')}
        ,{
          from: "${LAST_VALUE_START_DATE}",
          to: $to,
          category: DRYING,
          subCategory: BURNER_PERFORMANCE,
          variable: MEASUREMENT,
          index: 1,
          variableIndex: 1
          limit: "1"
          order: Descending
        }
        ,{
          from: "${LAST_VALUE_START_DATE}",
          to: $to,
          category: DRYING,
          subCategory: BURNER_PERFORMANCE,
          variable: ALARM_LIMIT_UPPER,
          index: 1,
          variableIndex: 1,
          limit: "1",
          order: Descending
        }
        ,{
          from: "${LAST_VALUE_START_DATE}",
          to: $to,
          category: DRYING,
          subCategory: BURNER_PERFORMANCE,
          variable: ALARM_LIMIT_UPPER_ACTIVE,
          index: 1,
          variableIndex: 1,
          limit: "1",
          order: Descending
        }
        ,{
          from: "${LAST_VALUE_START_DATE}",
          to: $to,
          category: DRYING,
          subCategory: BURNER_PERFORMANCE,
          variable: ALARM_LIMIT_LOWER,
          index: 1,
          variableIndex: 1,
          limit: "1",
          order: Descending
        }
        ,{
          from: "${LAST_VALUE_START_DATE}",
          to: $to,
          category: DRYING,
          subCategory: BURNER_PERFORMANCE,
          variable: ALARM_LIMIT_LOWER_ACTIVE,
          index: 1,
          variableIndex: 1,
          limit: "1",
          order: Descending
        }
        ,{
          from: "${LAST_VALUE_START_DATE}",
          to: $to,
          category: DRYING,
          subCategory: BURNER_PERFORMANCE,
          variable: WARNING_LIMIT_UPPER,
          index: 1,
          variableIndex: 1,
          limit: "1",
          order: Descending
        }
        ,{
          from: "${LAST_VALUE_START_DATE}",
          to: $to,
          category: DRYING,
          subCategory: BURNER_PERFORMANCE,
          variable: WARNING_LIMIT_UPPER_ACTIVE,
          index: 1,
          variableIndex: 1,
          limit: "1",
          order: Descending
        }
        ,{
          from: "${LAST_VALUE_START_DATE}",
          to: $to,
          category: DRYING,
          subCategory: BURNER_PERFORMANCE,
          variable: WARNING_LIMIT_LOWER,
          index: 1,
          variableIndex: 1,
          limit: "1",
          order: Descending
        }
        ,{
          from: "${LAST_VALUE_START_DATE}",
          to: $to,
          category: DRYING,
          subCategory: BURNER_PERFORMANCE,
          variable: WARNING_LIMIT_LOWER_ACTIVE,
          index: 1,
          variableIndex: 1,
          limit: "1",
          order: Descending
        }
      ]
    ) {
      values {
        value
        timestamp
      }
    }
  }
`

export const QUERY_FOR_OVERVIEW_RUNTIME_ANALYSIS = `
  query runtime ($deviceId: String!, $from: DateTime!, $to: DateTime!) {
    running: calculateTrend (
      deviceId: $deviceId
      data: {
        from: $from # now - 24h
        to: $to # now
        dataSources: [{
          category: PLANT_OVERVIEW
          subCategory: PLANT_STATUS
          variable: RUNNING
          index: 1
          variableIndex: 1
          aggregation: None
        }]
      }
    ) {
      values {
        value
        timestamp
      }
    }
  }
`
