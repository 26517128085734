import { gql } from '@apollo/client'
import AUTH from 'constants/auth'
import MENU from 'constants/menu'
import { DEVICES_LIST } from 'constants/storage'
import createClient from 'graphql/apolloClient'
import { QUERY_FOR_LOGIN } from 'graphql/queries'
import usePlsTranslation from 'hooks/usePlsTranslation'
import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import DeviceContext from 'contexts/device'
// import config from 'utils/dummyConfig'

export default () => {
  const { translate } = usePlsTranslation()
  const history = useNavigate()
  const { setDeviceId } = useContext(DeviceContext)

  const [errorMessage, setErrorMessage] = useState('')
  const [formData, setFormData] = useState<{ email: string; password: string }>(
    { email: '', password: '' },
  )

  const handleFieldChange = ({
    fieldName,
    newValue,
  }: {
    fieldName: string
    newValue: string
  }) =>
    setFormData((currentFormData) => {
      return { ...currentFormData, [fieldName]: newValue }
    })

  const prepareQuery = (queryString: string) => gql`
    ${queryString}
  `
  const prepareQueryVariables = () => {
    return {
      email: formData.email,
      password: formData.password,
    }
  }

  const handleLoginFormSubmission = async (event: any) => {
    event.preventDefault()
    const apolloClient = await createClient()
    const response = await apolloClient.query({
      query: prepareQuery(QUERY_FOR_LOGIN),
      variables: prepareQueryVariables(),
      fetchPolicy: 'no-cache',
    })
    if (response?.errors && response?.errors.length > 0) {
      setErrorMessage(translate('formValidation.invalidEmailOrPassword'))
      return
    }
    const {
      data: {
        login: {
          token,
          user: {
            accessibleCompanyDevices,
          },
        },
      },
    } = response
    localStorage.setItem(AUTH.TOKEN, token)
    localStorage.setItem(
      DEVICES_LIST,
      JSON.stringify(
        accessibleCompanyDevices.map(
          (device: { id: string; name: string; deviceConfig: JSON }) => {
            return {
              config: device.deviceConfig,
              id: device.id,
              text: device.name,
            }
          },
        ),
      ),
    )
    // set device id and wait for render cycle
    if (setDeviceId) setDeviceId(accessibleCompanyDevices[0].id)
    setTimeout(() => history(MENU.DASHBOARD.HREF))
  }

  return {
    errorMessage,
    handleFieldChange,
    handleLoginFormSubmission,
  }
}
